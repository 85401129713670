<template>
    <div id="my-creation">
        <leftbar-component></leftbar-component>
        <div class="content">
            <topbar-component :items="items"></topbar-component>
            <h1>我的作品</h1>
            <div class="search-bar">
                <div class="lf">
                    <div class="course-entrybox">
                        <i class="icon-search"></i>
                        <span>作品</span>
                        <input v-model="name" type="text">
                    </div>
                    <div class="calendar">
                        <el-date-picker
                                popper-class="popDateBox"
                                v-model="searchDate"
                                type="daterange"
                                range-separator="~"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                    <div class="btn-search" @click="getTableData(1)">查询</div>
                </div>
                <div class="rf">
                    <div>
                        <button @click="handleCreate">发布新作品</button>
                    </div>
                </div>
            </div>
            <div class="table">
                <el-table
                        :data="workList"
                        style="width: 100%">
                    <el-table-column
                        prop="id"
                        label="ID"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="作品名称">
                    </el-table-column>
                    <el-table-column
                        prop="releaseTime"
                        label="发布时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.releaseTime">{{ scope.row.releaseTime | formatDate }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="isRelease"
                            label="发布状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isRelease === 0" style="color: #666666;">未发布</span>
                            <span v-if="scope.row.isRelease === 1" style="color: #1890FF;">申请发布</span>
                            <span v-if="scope.row.isRelease === 2" style="color: #1DC084;">上架</span>
                            <el-tooltip placement="bottom" effect="light">
                                <span v-if="scope.row.isRelease === 3" style="color: #DC000C;">下架</span>
                                <template slot="content">
                                    <span>【下架原因】</span>
                                    <br/><br/>
                                    <span>{{ scope.row.releaseCause }}</span>
                                </template>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="320"
                            label="操作"
                            align="center">
                        <template slot-scope="scope">
                            <el-button
                                    type="warning"
                                    size="mini"
                                    @click="handleEdit(scope.$index, scope.row)" style="margin-right: 0;">编辑</el-button>
                            <el-button type="success" size="mini"
                                       :loading="btnLoadingRelease === scope.row.id" :disabled="scope.row.isRelease !== 0"
                                       @click="handleRelease(scope.row)">发布</el-button>
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)" style="margin: 0px;">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <listH5Component
                    :list="workList"
                >
                    <template v-slot:title="rowData" >
                        {{rowData.prop.name}}
                    </template>
                    <template v-slot:datetime="rowData" >
                        {{rowData.prop.releaseTime | formatDateTime}}
                    </template>
                    <template v-slot:status="rowData" >
                        <span v-if="rowData.prop.isRelease === 0" style="color: #666666;">未发布</span>
                        <span v-if="rowData.prop.isRelease === 1" style="color: #1890FF;">申请发布</span>
                        <span v-if="rowData.prop.isRelease === 2" style="color: #1DC084;">上架</span>
                        <span v-if="rowData.prop.isRelease === 3" style="color: #DC000C;">下架</span>
                    </template>
                    <template v-slot:btn="rowData">
                        <span
                            @click="handleEdit(rowData.prop.index,rowData.prop)" style="margin-right: 0;">编辑
                        </span>
                        <span 
                            v-if="!(rowData.prop.isRelease !== 0)" @click="handleRelease(rowData.prop)">发布
                        </span>
                        <el-popconfirm
                                confirm-button-text='确定'
                                cancel-button-text='取消'
                                icon="el-icon-info"
                                icon-color="red"
                                title="确定删除？"
                                @confirm="handleDelete(rowData.index,rowData.prop)"
                        >
                            <span slot="reference">
                                删除
                            </span>
                        </el-popconfirm>
                    </template>
                </listH5Component>
                <div class="pagination" v-if="workListTotal > 10">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            @current-change="getTableData"
                            :current-page.sync="workListPage"
                            :page-size="workListPageSize"
                            :total="workListTotal">
                    </el-pagination>
                </div>
            </div>

            <!-- 编辑作品 -->
            <el-dialog
                title="编辑作品"
                width="35%"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                center
            >
                <el-form :model="form" label-width="80px">
                    <el-form-item label="作品名称">
                        <el-input v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="上传视频">
                        <el-upload
                                class="upload-video"
                                action=""
                                :on-change="handleVideoChange"
                                :on-remove="handleVideoRemove"
                                accept=".mp4"
                                :limit="1"
                                :auto-upload="false"
                                :file-list="fileList"
                                ref="elUpload">
                            <template>
                                <el-button type="primary" size="small">选择文件</el-button>
                            </template>
                        </el-upload>
<!--                        <p v-if="workList.length > 0 && savedIdx !== null && workList[savedIdx] && form.videoUrl && workList[savedIdx].videoName">{{ workList[savedIdx].videoName }}</p>-->
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="save">保 存</el-button>
                </div>
            </el-dialog>
    
            <!-- 上传进度遮罩 -->
            <el-dialog
                    :visible.sync="uploadDialogVisible"
                    class="upload-dialog"
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                    :fullscreen="true"
                    append-to-body>
                <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                </div>
                <div class="upload-dialog-text">正在上传视频...</div>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    import LeftbarComponent from './component/leftbarComponent'
    import TopbarComponent from './component/topbarComponent'
    import {urls, banbaoUrl} from '@/lib/common';

    import listH5Component from './component/listH5Component'
    import router from "../../router";

    export default {
        name: "MyCreation",
        components: {
            LeftbarComponent,
            TopbarComponent,
            listH5Component
        },
        data() {
            return {
                banbaoUrl,
                roles: null,
                items: [
                    {
                        text: '个人中心',
                        disabled: false,
                        href: '/userCenter',
                    }, {
                        text: '我的作品',
                        disabled: true,
                        href: '/myCreation',
                    }
                ],

                workList: [],
                workListPage: 1,
                workListPageSize: 10,
                workListTotal: 0,
                name: '',
                searchDate: [],

                dialogVisible: false,
                editing: false,
                form: {
                    id: 0,
                    name: '',
                },
                fileList: [],
                
                btnLoadingRelease: false,
                uploadDialogVisible: false,
                savedIdx: null,
            }
        },
        created() {
            this.roles = sessionStorage.getItem('roles') || this.$cookies.get('app.roles', {domain: banbaoUrl, path: ''});
            this.getTableData();
        },
        methods: {
            getTableData: function(page = 1) {
                let that = this;

                let params = {
                    name: that.name,
                    startDate: that.searchDate && that.searchDate[0] ? `${this.dateFormat(that.searchDate[0])} 00:00:00` : '',
                    endDate: that.searchDate && that.searchDate[1] ? `${this.dateFormat(that.searchDate[1])} 23:59:59` : '',
                    pageCurrent: page,
                    pageSize: that.workListPageSize,
                };

                that.$http.get(urls.userServer.opusFrontList, {params}).then((res) => {
                    if(res.data.status === 200) {
                        that.workList = res.data.data.records;
                        that.workListTotal = res.data.data.total;
                    }
                });
            },
            loginOvertimeHandle: function() {
                let info = {
                    pathName: router.history.current.name,
                    params: router.history.current.params
                }

                if (router.history.current.query && router.history.current.query.n) {
                    info.query = {
                        n: router.history.current.query.n
                    };
                }
                sessionStorage.setItem('prevPath', JSON.stringify(info))
                sessionStorage.removeItem('token')
                sessionStorage.removeItem('userinfo')
                sessionStorage.removeItem('roles')
                setTimeout(() => {
                    window.location.href='/login?editorType=0';
                }, 2000)
            },
            handleVideoChange(file, fileList) { // 视频上传
                console.log(file, fileList);
                const fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase() ;
                if (fileType !== 'mp4') {
                    this.$message.warning('请上传MP4格式文件')
                    return false
                }
                if (file.size > 100 * 1024 * 1024) {
                    this.$message.warning('文件过大，请压缩后重新上传')
                    return false
                }
                this.fileList = fileList;
                this.uploadDialogVisible = true;
                this.fileUpload(file, fileList);
            },
            fileUpload(file, fileList) {
                let formData = new FormData();
                formData.append('videoName', file.name);
                formData.append('file', file.raw);
                this.$http({
                    url: urls.studentOpusUpload,
                    data: formData,
                    method: 'post',
                    headers: {'Content-Type': 'multipart/form-data'},
                }).then((res) => {
                    this.uploadDialogVisible = false;
                    if (res.data.status === 200) {
                        this.form.videoName = file.name;
                        this.form.videoUrl = res.data.data;
                        this.$message.success("上传成功!~");
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                }).catch(err => {
                    console.log(err)
                    this.uploadDialogVisible = false;
                    this.$message.error(err.message);
                })
            },
            handleVideoRemove(file, fileList) {
                console.log('handleVideoRemove', file, fileList)
                this.fileList = fileList;
            },
            handleCreate: function() {
                let that = this;
                if (this.$cookies.get('app.token', {domain: banbaoUrl, path: ''})) {
                    window.open(`${process.env.VUE_APP_TOY_BRICK}`, '_blank');
                } else {
                    that.$message.warning('请登录');
                    that.loginOvertimeHandle();
                }
            },
            handleEdit(index, row) {
                if (this.savedIdx === null) {
                    this.savedIdx = index;
                } else if (this.savedIdx !== index && this.savedIdx !== null) {
                    this.savedIdx = index;
                    this.$refs.elUpload.clearFiles();
                }
                this.editing = true;
                this.form.id = row.id;
                this.form.name = row.name;
                this.dialogVisible = true;
                if (row.videoName) {
                    this.fileList = [{
                        name: row.videoName
                    }];
                }
            },
            handleRelease: function(row) {
                let that = this;
                that.btnLoadingRelease = row.id;

                that.$http.get(`${urls.userServer.opusFrontPublish}/${row.id}`).then((res) => {
                    if(res.data.status === 200) {
                        that.$message.success('操作成功~');
                        that.getTableData(that.workListPage);
                        that.btnLoadingRelease = false;
                    } else {
                        that.btnLoadingRelease = false;
                    }
                });
            },
            save: function() {
                let that = this;
                console.log('save', that.form);
                that.$http.post(urls.userServer.opusFrontUpdateOpusName, {
                    ...that.form,
                }).then((res) => {
                    if(res.data.status === 200) {
                        that.$message.success('保存成功~');
                        that.getTableData(1);
                        this.dialogVisible = false;
                    }
                });
            },
            handleDelete(index, row) {
                let that = this;

                that.$confirm('是否删除该作品', '确认信息', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消'
                }).then(() => {
                    that.$http.get(`${urls.userServer.opusFrontDel}/${row.id}`).then((res) => {
                        if(res.data.status === 200) {
                            that.$message.success('操作成功~');
                            that.getTableData(1);
                        }
                    });
                }).catch();
            },

            dateFormat(date) {
                const y = date.getFullYear();
                const m = date.getMonth() + 1;
                const d = date.getDate();
                const month = m >= 10 ? m : '0' + m;
                const day = d >= 10 ? d : '0' + d;
                return `${y}-${month}-${day}`
            },
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    #my-creation {
        display: flex;
        justify-content: flex-start;
        .content {
            width: 84%;
            box-sizing: border-box;
            background: #F2FCFF;
            padding-bottom: 5%;

            >h1 {
                font-size: 1.25vw;
                margin: 1vw 0 1vw 2vw;
            }
            .search-bar {
                margin: 1vw 0 1vw 2vw;
                width: 90%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.73vw;
                .lf {
                    display: flex;
                    align-items: center;
                    .course-entrybox {
                        background-color: #fff;
                        margin-right: 10px;
                        line-height: 40px;
                        border-radius: 4vw;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #666;
                        .icon-search {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            background: url("../../assets/images/user-center/icon_search_dark.png") no-repeat center center;
                            background-size: 100%;
                            margin-left: 10px;
                        }
                        span {
                            display: inline-block;
                            margin-left: 10px;
                            padding-right: 10px;
                            border-right: 1px solid #eaeaea;
                        }
                        input {
                            width: 200px;
                            padding: 0 15px 0 10px;
                        }
                    }
                    .calendar {
                        margin-right: 10px;
                        @{deep} .el-date-editor {
                            border: 0;
                            border-radius: 100px;
                        }
                    }
                    .btn-search {
                        background-color: #24CEFF;
                        color: #fff;
                        line-height: 40px;
                        padding: 0 1.5vw;
                        cursor: pointer;
                        border-radius: 100px;
                        transition: all .3s linear;
                        &:hover {
                            background-color: #129DC6;
                        }
                    }
                }
                .rf {
                    div {
                        display: inline-block;
                        button {
                            color: #fff;
                            line-height: 40px;
                            padding: 0 2vw;
                            border-radius: 4vw;
                            outline: none;
                            background-color: #48A2FF;
                            transition: all .3s linear;
                            &:hover {
                                background-color: #2a629c;
                                box-shadow: 0 2px 5px 1px rgba(60,60,60,.5);
                            }
                            &:last-child {
                                background: #1AAB7D;
                                margin-left: 30px;
                                &:hover {
                                    background-color: #157C5D;
                                }
                            }
                        }
                    }
                }
            }
            .table {
                width: 90%;
                margin: 1vw 0 1vw 2vw;
                @{deep} .el-table {
                    &::before {
                        width: 0;
                        height: 0;
                    }
                    thead {
                        th {
                            background-color: #24CEFF;
                            color: #fff;

                        }
                        th:first-child {
                            border-radius: 10px 0 0 0;
                        }
                        th:nth-child(5) {
                            border-radius: 0 10px 0 0;
                        }
                    }
                    tbody {
                        .el-table__row:last-child {
                            border-radius: 0 0 10px 10px;
                            border-bottom: 0;
                            td:first-child {
                                border-radius: 0 0 0 10px;
                            }
                            td:last-child {
                                border-radius: 0 0 10px 0;
                            }
                        }
                        .el-table__row {
                            .cell {
                                .el-button--mini {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                    .upload-video {
                        display: inline-block;
                    }
                }
                .pagination {
                    text-align: right;
                    margin: 1vw 0 0 0;
                    @{deep} {
                        .el-pagination {
                            .el-pager li {
                                background-color: #fff;
                                &.active {
                                    background-color: #24CEFF;
                                }
                            }
                            .btn-next,
                            .btn-prev {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .upload-dialog {
        @{deep} .el-dialog.is-fullscreen {
            background-color: rgba(0, 0, 0, .3);
        }
    }
    .el-dialog__wrapper {
        @{deep} .el-upload-list {
            li {
                transition-duration: 0ms !important;
            }
        }
    }
    
    .upload-dialog-text {
        text-align: center;
        color: #fff;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
    }
    .sk-chase {
        width: 50px;
        height: 50px;
        position: relative;
        animation: sk-chase 2.5s infinite linear both;
        margin: 30vh auto 0;
    }

    .sk-chase-dot {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        animation: sk-chase-dot 2.0s infinite ease-in-out both;
    }

    .sk-chase-dot:before {
        content: '';
        display: block;
        width: 25%;
        height: 25%;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 100%;
        animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
    }

    .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
    .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
    .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
    .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
    .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
    .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
    .sk-chase-dot:nth-child(1):before { background-color: lightskyblue; animation-delay: -1.1s; }
    .sk-chase-dot:nth-child(2):before { background-color: lightblue; animation-delay: -1.0s; }
    .sk-chase-dot:nth-child(3):before { background-color: lightgreen; animation-delay: -0.9s; }
    .sk-chase-dot:nth-child(4):before { background-color: lightyellow; animation-delay: -0.8s; }
    .sk-chase-dot:nth-child(5):before { background-color: lightsalmon; animation-delay: -0.7s; }
    .sk-chase-dot:nth-child(6):before { background-color: lightcoral; animation-delay: -0.6s; }

    @keyframes sk-chase {
        100% { transform: rotate(360deg); }
    }

    @keyframes sk-chase-dot {
        80%, 100% { transform: rotate(360deg); }
    }

    @keyframes sk-chase-dot-before {
        50% {
            transform: scale(0.4);
        } 100%, 0% {
              transform: scale(1.0);
          }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #my-creation {
            flex-direction: column;
            background: #F2FCFF;
            .content{
                width: 100%;
                margin-top:-40%;
                background: none;
                .topbar{
                    display: none;
                }
                &>h1{
                    background: #fff;
                    width: 94.7%;
                    margin:0 auto;
                    .fontFn(10,border-top-left-radius);
                    .fontFn(10,border-top-right-radius);
                    padding: 0 30px;
                    color:#34254C;
                    font-weight: bold;
                    .fontFn(18);
                    .fontFn(48,height);
                    .fontFn(48,line-height);
                }
                &>.search-bar{
                    background: #fff;
                    flex-direction: column;
                    .fontFn(10,border-bottom-left-radius);
                    .fontFn(10,border-bottom-right-radius);
                    width: 94.7%;
                    margin:0 auto;
                    padding:0 26px 3%;
                    margin-bottom: 5%;
                    box-shadow: 0px 5px 10px #D5F5FF;
                    .lf{
                        flex-direction: column;
                        width: 100%;
                        .course-entrybox{
                            .fontFn(20,border-radius);
                            width: 100%;
                            background: #F3F3F3;
                            margin-right: 0;
                            margin-bottom: 5%;
                            .fontFn(38,line-height);
                            .icon-search{
                                .fontFn(18,width);
                                .fontFn(18,height);
                                margin-left: 16px;
                            }
                            span{
                                color:#555859;
                                .fontFn(14);
                                .fontFn(26,line-height);
                            }
                            input{
                                width: auto;
                                flex-grow:1;
                                .fontFn(14);
                            }
                        }
                        .course-status-options{
                            width: 100%;
                            margin-bottom: 5%;

                            @{deep} .el-select{
                                width: 100%;
                            }

                            @{deep} .el-input__inner{
                                padding: 0 32px 0 16px;
                                .fontFn(14);
                                .fontFn(20,border-radius);
                                border:none;
                                background: #F3F3F3;
                                .fontFn(38,height);
                                .fontFn(38,line-height);
                            }
                        }
                        .calendar{
                            width: 100%;
                            margin:0 auto 5%;
                            .el-date-editor{
                                padding: 0;
                                width: 100%;
                                background: #F3F3F3;
                                .fontFn(20,border-radius);
                                @{deep} .el-range__icon{
                                    .fontFn(18);
                                    height: 32px;
                                    margin-left: 16px;
                                }
                                @{deep} .el-range-separator{
                                    height: 10px;
                                    line-height: 10px;
                                }
                                @{deep} .el-range-input{
                                    .fontFn(38,line-height);
                                    .fontFn(14);
                                }
                            }
                        }
                        .btn-search{
                            width: 100%;
                            text-align: center;
                            .fontFn(14);
                            .fontFn(38,height);
                            .fontFn(38,line-height);
                            margin-bottom: 5%;
                        }
                    }
                    .rf{
                        width: 100%;
                        text-align: center;
                        .fontFn(14);
                        .fontFn(38,height);
                        .fontFn(38,line-height);
                        margin-bottom: 5%;
                    }
                }

                .table{
                    .el-table{
                        display: none;
                    }
                    width:94.7%;
                    margin:0 auto;
                }

                .el-dialog__wrapper{
                    @{deep} .el-dialog{
                        width: 96% !important;
                    }

                    @{deep} .el-dialog__body{
                        padding:20px 10px;
                    }

                    @{deep} .el-form-item__label{
                        width: 80px !important;
                    }

                    @{deep} .el-form-item__content{
                        margin-left:80px !important;
                    }

                    @{deep} .el-textarea__inner{
                        min-height: 120px !important;
                    }

                    @{deep} .el-row{
                        img{
                            width: 320px !important;
                            display: block;
                            margin:0 auto;
                        }
                    }
                }

                .dialog{
                    .courseware{
                        label{margin-bottom:3%;}
                        .courseware-form{
                            .search-bar{
                                flex-direction: column;
                                &>span{
                                    display: none;
                                }
                                .el-input{
                                    margin-bottom: 3%;
                                }
                                .el-range-editor.el-input__inner{
                                    width: 100% !important;
                                    margin:0 auto 3% !important;
                                }
                                .el-button{
                                    width: 100%;
                                    margin-bottom: 3%;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
</style>


<style lang="less">
@deep: ~'>>>';
@media screen and (max-width: 750px) {
    .el-dialog__wrapper{
        .el-dialog{
            width: 96% !important;
        }

        .vue-cropper{
            width: 100% !important;
        }

        .cropper-crop-box{
            transform: translate3d(0,0,0) !important;
        }
    }

    .el-message-box{
        width: 96%;
    }

    .popDateBox{
        width: auto !important;
        top:2% !important;
        left:2% !important;
        right:2% !important;
        margin:0 !important;
        background:rgba(0,0,0,0.5);

        .popper__arrow{
            display: none;
        }

        .el-picker-panel__body{

            min-width: auto !important;
            background: #fff !important;
        }

        .el-date-range-picker__content{
            float:none !important;
            width: 100% !important;
        }
    }
}
</style>